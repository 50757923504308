import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-privacy-policy',
    standalone: true,
    imports: [],
    templateUrl: './privacy-policy.component.html',
    styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
    constructor(readonly http: HttpClient) {}

    getPrivacyPolicy(): Observable<string> {
        return this.http.get(
            'assets/file/Polyview_meeting_room_schedule_display.html',
            {
                responseType: 'text',
            }
        );
    }
}
