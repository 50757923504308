<div class="page-container">
    <div class="dialog-container">
        <div class="title" mat-dialog-title>
            <img src="../../../../assets/images/succuss.png" alt="" />

            @if (!dialogData.isForUpdate) {
            <p>កក់បានជោគជ័យ!</p>
            }@else {
            <p>កែប្រែបានជោគជ័យ!</p>
            }
        </div>
        <div class="content" mat-dialog-content>
            <div class="header">
                <h3>{{ session?.subject }}</h3>
                <p>
                    {{ session?.startDateTime | date }} ({{
                        getTimeFn(session?.startDateTime)
                    }}
                    - {{ getTimeFn(session.endDateTime) }})
                </p>
            </div>
            <div class="border"></div>
            <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                    <p>Meeting room:</p>
                    <p class="text-black">{{ session?.room }}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p>Organizer:</p>
                    <p class="text-black">{{ session?.organizer }}</p>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="btn-container">
            <button
                type="button"
                mat-flat-button
                color="primary"
                class="default-btn rounded"
                (click)="confirm()"
            >
                <span>{{ "បិទ" | translate }}</span>
            </button>
        </div>
    </div>
</div>
