<div class="file-item">
    <img src="" class="icon" src="assets/images/acrobat.png" alt="file" />
    <div class="file-info">
        <p class="name">{{ file?.filename }}</p>
    </div>
    <div class="action">
        @if (allowView) {
        <button
            type="button"
            class="view-action-btn view"
            color="primary"
            mat-mini-fab
            matRipple
            (click)="view()"
        >
            <mat-icon>visibility</mat-icon>
        </button>
        } @if (allowDelete) {
        <button
            type="button"
            class="view-action-btn delete"
            mat-mini-fab
            matRipple
            color="warn"
            (click)="delete()"
        >
            <mat-icon>delete_forever</mat-icon>
        </button>
        }
    </div>
</div>
