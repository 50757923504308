import { Routes } from '@angular/router';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { MenuItem } from './models/menu-item';
import { PermissionEnum } from './models/enums/permission-enum';
import { noNeedLoginGuard } from './guards/no-need-login.guard';
import { UserRoleEnum } from './models/enums/user-role-enum';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { MeetingPageComponent } from './routes/meeting-page/meeting-page.component';
import { PrivacyPolicyComponent } from './routes/privacy-policy/privacy-policy.component';

export const routes: Routes = [
    {
        path: '',
        component: MainContainerComponent,
        children: [
            {
                path: 'dashboard',
                data: {
                    type: 'dashboard',
                    isSub: false,
                    index: 1,
                    roles: [
                        UserRoleEnum.SYSTEM_ADMIN,
                        UserRoleEnum.ADMIN,
                        UserRoleEnum.SELLER,
                        UserRoleEnum.RESELLER,
                    ],
                    icon: 'dashboard',
                },
                loadChildren: () =>
                    import('./routes/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'sessions',
                data: {
                    type: 'sessions',
                    isSub: false,
                    index: 1,
                    roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
                    icon: 'calendar_month',
                },
                loadChildren: () =>
                    import('./routes/sessions/sessions.module').then(
                        (m) => m.SessionsModule
                    ),
            },

            {
                path: 'client',
                data: {
                    type: 'client',
                    isSub: false,
                    index: 4,
                    roles: [
                        UserRoleEnum.SYSTEM_ADMIN,
                        UserRoleEnum.SELLER,
                        UserRoleEnum.RESELLER,
                    ],
                    icon: 'how_to_reg',
                },
                loadChildren: () =>
                    import('./routes/client/client.module').then(
                        (m) => m.ClientModule
                    ),
            },
            {
                path: 'organization',
                data: {
                    type: 'organization',
                    isSub: false,
                    index: 4,
                    roles: [
                        UserRoleEnum.SYSTEM_ADMIN,
                        UserRoleEnum.SELLER,
                        UserRoleEnum.RESELLER,
                    ],
                    icon: 'store',
                },
                loadChildren: () =>
                    import('./routes/organizations/organizations.module').then(
                        (m) => m.OrganizationsModule
                    ),
            },
            {
                path: 'devices',
                data: {
                    type: 'devices',
                    isSub: false,
                    index: 5,
                    roles: [UserRoleEnum.SYSTEM_ADMIN, UserRoleEnum.SELLER],
                    icon: 'screenshot_monitor',
                },
                loadChildren: () =>
                    import('./routes/devices/devices.module').then(
                        (m) => m.DevicesModule
                    ),
            },
            {
                path: 'organization-admin',
                data: {
                    type: 'organization-admin',
                    isSub: false,
                    index: 4,
                    roles: [
                        UserRoleEnum.SYSTEM_ADMIN,
                        UserRoleEnum.SELLER,
                        UserRoleEnum.RESELLER,
                    ],
                    icon: 'groups',
                },
                loadChildren: () =>
                    import('./routes/user-module/user-module.module').then(
                        (m) => m.UserModuleModule
                    ),
            },
            {
                path: 'subscription-plan',
                data: {
                    type: 'subscription_plan',
                    isSub: false,
                    index: 6,
                    roles: [UserRoleEnum.SYSTEM_ADMIN, UserRoleEnum.SELLER],
                    icon: 'currency_exchange',
                },
                loadChildren: () =>
                    import(
                        './routes/subscription-plan/subscription-plan.module'
                    ).then((m) => m.SubscriptionPlanModule),
            },
            {
                path: 'users',
                data: {
                    type: 'users',
                    isSub: false,
                    show: true,
                    index: 11.1,
                    roles: [UserRoleEnum.ADMIN],
                    icon: 'group',
                },
                loadChildren: () =>
                    import('./routes/users/users.module').then(
                        (m) => m.UsersModule
                    ),
            },
            {
                path: 'setting',
                data: {
                    type: 'setting',
                    isSub: false,
                    index: 14,
                    roles: [UserRoleEnum.ADMIN],
                    icon: 'settings',
                },
                loadChildren: () =>
                    import('./routes/setting/setting.module').then(
                        (m) => m.SettingModule
                    ),
            },
            {
                path: 'artifact-management',
                data: {
                    type: 'artifact_management',
                    isSub: false,
                    index: 12,
                    roles: [UserRoleEnum.SYSTEM_ADMIN, UserRoleEnum.DEVELOPER],
                    icon: 'devices',
                },
                loadChildren: () =>
                    import(
                        './routes/artifact-management/artifact-management.module'
                    ).then((m) => m.ArtifactManagementModule),
            },
            {
                path: 'organizers',
                data: {
                    type: 'organizers',
                    isSub: false,
                    index: 13,
                    roles: [UserRoleEnum.ADMIN],
                    icon: 'group_add',
                },
                loadChildren: () =>
                    import('./routes/organizer/organizer.module').then(
                        (m) => m.OrganizerModule
                    ),
            },
        ],
    },
    {
        path: 'book-room/:organization_id/:room_id',
        component: MeetingPageComponent,
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
    },
    {
        path: 'login',
        canActivate: [noNeedLoginGuard],
        loadChildren: () =>
            import('./routes/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];
export const MENUITEMS: MenuItem[] = routes[0].children.map((routeItem) => {
    return {
        route: routeItem.path,
        type: routeItem.data?.type,
        permissions: routeItem.data?.permissions as PermissionEnum[],
        title: routeItem.path.split('/').pop(),
        icon: routeItem.data?.icon,
        iconType: routeItem.data?.iconType,
        isSub: routeItem.data?.isSub,
        index: routeItem.data?.index ?? 0,
        show: routeItem.data?.show,
        roles: routeItem.data?.roles,
    } as MenuItem;
});
