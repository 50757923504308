import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    FormsModule,
    FormGroupDirective,
    FormArray,
    AbstractControl,
} from '@angular/forms';
import { InputFieldComponent } from '../../shared/components/input-field/input-field.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { Utils } from '../../utils/utils';
import { SessionService } from '../../services/session.service';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import { Session } from '../../models/session';
import { BaseQuery } from '../../models/query/base-query';
import { map, Observable, of, startWith } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { BookMeetingDialogComponent } from '../../shared/components/book-meeting-dialog/book-meeting-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { DeleteFormConfirmDialogComponent } from '../../shared/components/delete-form-confirm-dialog/delete-form-confirm-dialog.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { BaseDialogData } from '../../models/bases/base-dialog-data';
import { AppFile } from '../../models/bases/app-file';
import { FileService } from '../../services/file.service';
import { FileItemForPhoneComponent } from '../../shared/components/file-item-for-phone/file-item-for-phone.component';

@Component({
    selector: 'app-meeting-page',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        InputFieldComponent,
        MatDatepickerModule,
        MatSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        DatePipe,
        MatAutocompleteModule,
        AsyncPipe,
        FormsModule,
        MatChipsModule,
        NgxMatTimepickerModule,
        CommonModule,
        MatMenuModule,
        FileItemForPhoneComponent,
    ],
    providers: [CustomSnackbarComponent, BookMeetingDialogComponent],
    templateUrl: './meeting-page.component.html',
    styleUrl: './meeting-page.component.scss',
})
export class MeetingPageComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: false })
    formGroupDirective: FormGroupDirective;
    @ViewChild('fileInput') fileInput: ElementRef;

    sessionQuery: BaseQuery = {
        date: new Date(),
        organization: '',
        room: '',
    };

    organizaerQuery: BaseQuery = {
        organization: '',
    };

    form: FormGroup;
    routes: string[] = [];
    isLoading: boolean = true;
    hasFetched: boolean = false;

    sessions: Session[] = [];
    currentDateTime: Date = new Date();

    organizers: string[] = [];
    filterdOrganizer: Observable<string[]> = of([]);
    organizerControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
    ]);

    currentTime: string;

    currentUserId: string = '';
    sessionId: string = '';
    isForUpdate: boolean = false;

    participantSuggess: string[] = [];
    filterdParticipant: Observable<string[]>[] = [];

    uploadedFiles: AppFile[] = [];
    uploadingFile = false;

    uploadedAgendaFiles: AppFile[] = [];
    uploadingAgendaFile = false;

    uploadedReportFiles: AppFile[] = [];
    uploadingReportFile = false;

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        readonly _utils: Utils,
        readonly _sessionService: SessionService,
        readonly _snackBar: CustomSnackbarComponent,
        readonly _translateService: TranslateService,
        readonly _announcer: LiveAnnouncer,
        readonly _router: Router,
        readonly _dialog: MatDialog,
        readonly _localStorageService: LocalStorageService,
        readonly _fileService: FileService
    ) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
        iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/images/delete.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'emptyz',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/images/empty.svg')
        );
    }

    ngOnInit(): void {
        this.currentUserId = localStorage.getItem('userId') || '';

        this.handleGetRoutes();
        this.getCurrentTime();

        this.form = new FormGroup({
            startTime: new FormControl(this.currentTime, [Validators.required]),
            endTime: new FormControl(this.addOneHour(this.currentTime), [
                Validators.required,
            ]),
            startDateTime: new FormControl(this.currentDateTime, [
                Validators.required,
            ]),
            subject: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
            ]),
            endDateTime: new FormControl(
                this.dateTimeAddOneHour(this.currentDateTime)
            ),
            participants: new FormArray([]),
            attachments: new FormControl([]),
            agendaFiles: new FormControl([]),
            reportFiles: new FormControl([]),
        });

        this.handleSessionQueryChange();
        this.handleOrganizerQueryChange();

        this.handleStartDateTimeChange();
        this.handleStartTimeChange();
        this.handleSessionsQuery();
        this.getOrganizers();

        this.getParticipantSuggessLists();
    }

    getUserId(): string {
        let userId = localStorage.getItem('userId');
        if (!userId) {
            userId = crypto.randomUUID();
            localStorage.setItem('userId', userId);
        }
        return userId;
    }

    getCurrentTime() {
        const now = new Date();
        const hour = now.getHours();
        const minute = now.getMinutes();
        const time = `${hour}:${minute}`;

        this.currentTime = time;
    }

    handleGetRoutes(): void {
        const currentUrl = this._router.url;
        const basePath = currentUrl.split('?')[0];
        this.routes = basePath.split('/').filter((segment) => segment);
    }

    async getSessions(query: any) {
        this.isLoading = true;
        try {
            const res = await this._sessionService.getPublicSessions(query);
            if (res && this._utils.isListData<Session>(res)) {
                this.sessions = res.list;
            } else {
                this.sessions = [];
            }
        } catch (error) {
            console.error(error);
            this.sessions = [];
        } finally {
            this.isLoading = false;
            this.hasFetched = true;
        }
    }

    async onDeleteSession(id: string) {
        const dialogData: BaseDialogData = {
            isForUpdate: false,
            data: {
                item: {
                    submitText: 'បាទ/ចាស',
                },
            },
        };

        const dialogRef = this._dialog.open(DeleteFormConfirmDialogComponent, {
            data: dialogData,
            panelClass: 'custom-dialog',
        });
        const result = await dialogRef.afterClosed().toPromise();
        if (result?.confirmed) {
            await this._sessionService.deleteSession(id);
            this.getSessions(this.sessionQuery);
        }
    }

    onUpdateSession(id?: string) {
        const session = this.sessions.find((session) => session._id === id);

        if (session) {
            this.isForUpdate = true;
            this.sessionId = id;

            this.participants.clear();

            const startDateTime = new Date(session.startDateTime);
            const startTime = startDateTime.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });

            const endDateTime = new Date(session.endDateTime);
            const endTime = endDateTime.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            });

            this.form.patchValue({
                startTime: startTime,
                endTime: endTime,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                subject: session.subject,
            });
            this.organizerControl.setValue(session.organizer);

            session.participants.forEach((participant) => {
                this.participants.push(
                    new FormGroup({
                        email: new FormControl(participant, [
                            Validators.email,
                            Validators.pattern(
                                '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
                            ),
                        ]),
                    })
                );
            });

            const attachments = session.attachments;
            if (attachments && Array.isArray(attachments)) {
                this.uploadedFiles = attachments.map((file) => ({
                    ...file,
                    isNew: false,
                }));
                this.form.patchValue({
                    files: this.uploadedFiles.map((f) => f._id),
                });
            }

            const agendaFiles = session.agendaFiles;
            if (agendaFiles && Array.isArray(agendaFiles)) {
                this.uploadedAgendaFiles = agendaFiles.map((file) => ({
                    ...file,
                    isNew: false,
                }));
                this.form.patchValue({
                    files: this.uploadedAgendaFiles.map((f) => f._id),
                });
            }

            const reportFiles = session.reportFiles;
            if (reportFiles && Array.isArray(reportFiles)) {
                this.uploadedReportFiles = reportFiles.map((file) => ({
                    ...file,
                    isNew: false,
                }));
                this.form.patchValue({
                    files: this.uploadedReportFiles.map((f) => f._id),
                });
            }

            this.form.updateValueAndValidity();

            setTimeout(() => {
                this.scrollToTopWhenClickUpdate();
            }, 0);
        }
    }

    scrollToTopWhenClickUpdate() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    handleSessionQueryChange() {
        this.sessionQuery = {
            ...this.sessionQuery,
            room: this.getDeviceName(this.routes[2]),
            organization: this.routes[1],
        };
    }

    handleOrganizerQueryChange() {
        this.organizaerQuery = {
            organization: this.routes[1],
        };
    }

    handleSessionsQuery() {
        if (!this.form.get('startDateTime').value) {
            this.sessionQuery['date'] = this.currentDateTime.toISOString();

            this.getSessions(this.sessionQuery);
        } else {
            this.sessionQuery['date'] = new Date().toISOString();

            this.getSessions(this.sessionQuery);
        }
    }

    handleStartDateTimeChange() {
        this.form.controls.startDateTime.valueChanges.subscribe(
            (date: Date) => {
                if (date) {
                    this.sessionQuery.date = date;
                    this.getSessions(this.sessionQuery);

                    const now = new Date();
                    const currentDate = now.getDate();
                    const selectedDateIsoString = new Date(date);
                    const selectedDate = selectedDateIsoString.getDate();

                    if (currentDate !== selectedDate) {
                        this.currentTime = '00:00';
                    } else {
                        const now = new Date();
                        const hour = now.getHours();
                        const minute = now.getMinutes();

                        this.currentTime = `${hour}:${minute}`;
                    }
                }
            }
        );
    }

    handleStartTimeChange(): void {
        this.form.controls.startTime.valueChanges.subscribe(
            (startTime: string) => {
                if (startTime) {
                    const startDate =
                        this.form.controls.startDateTime.value ||
                        this.currentDateTime;
                    const dateTime = this.combineDateAndTime(
                        startDate,
                        startTime
                    );

                    this.form.controls.startDateTime.setValue(dateTime);
                    this.form.controls.endTime.setValue(
                        this.addOneHour(startTime)
                    );
                    this.checkConflictMeeting(dateTime, true);
                    this.validateEndTime();
                }
            }
        );

        this.form.controls.endTime.valueChanges.subscribe((endTime: string) => {
            if (endTime) {
                const endDate =
                    this.form.controls.startDateTime.value ||
                    this.currentDateTime;
                const dateTime = this.combineDateAndTime(endDate, endTime);

                this.form.controls.endDateTime.setValue(dateTime);

                this.checkConflictMeeting(dateTime, false);
                this.validateEndTime();
            }
        });
    }

    validateEndTime(): void {
        const startTime = this.form.get('startTime')?.value;
        const endTime = this.form.get('endTime')?.value;

        if (startTime && endTime) {
            const startDateTime = this.combineDateAndTime(
                this.currentDateTime,
                startTime
            );
            const endDateTime = this.combineDateAndTime(
                this.currentDateTime,
                endTime
            );

            if (endDateTime < startDateTime) {
                this.form
                    .get('endTime')
                    ?.setErrors({ endTimeBeforeStartTime: true });

                this.form.get('endTime')?.markAsTouched();
            } else {
                this.form.get('endTime')?.setErrors(null);
            }
        }
    }

    combineDateAndTime(date: Date, time: string): Date {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const combinedDate = new Date(date);
        combinedDate.setHours(hours, minutes, 0, 0);
        return combinedDate;
    }

    checkConflictMeeting(selectedDate: Date, forStartTime: boolean) {
        if (!selectedDate) return;

        const selectedTime = selectedDate.getTime();

        this.sessions.forEach((meeting) => {
            const meetingStartTime = new Date(meeting.startDateTime).getTime();
            const meetingEndTime = new Date(meeting.endDateTime).getTime();

            if (forStartTime) {
                if (
                    selectedTime >= meetingStartTime &&
                    selectedTime < meetingEndTime
                ) {
                    if (!this.isForUpdate) {
                        this.form
                            .get('startTime')
                            ?.setErrors({ conflict: true });
                    }
                }
            } else {
                if (
                    selectedTime > meetingStartTime &&
                    selectedTime <= meetingEndTime
                ) {
                    if (!this.isForUpdate) {
                        this.form.get('endTime')?.setErrors({ conflict: true });
                    }
                }
            }
        });
    }

    async getOrganizers() {
        try {
            const res = await this._sessionService.getPublicOrganizers(
                this.organizaerQuery
            );
            if (res && this._utils.isData<string[]>(res)) {
                this.organizers = res;
                this.setupOrganizerFiltering();
            } else {
                this.organizers = [];
            }
        } catch (error) {
            console.error(error);
            this.organizers = [];
        }
    }

    setupOrganizerFiltering(): void {
        this.filterdOrganizer = this.organizerControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterOrganizer(value))
        );
    }

    onOrganizerTouched(): void {
        this.organizerControl.markAsTouched();
    }

    _filterOrganizer(value: string): string[] {
        const filterValue = value?.toLowerCase();
        return this.organizers.filter((option) =>
            option?.toLowerCase().includes(filterValue)
        );
    }

    async getParticipantSuggessLists() {
        try {
            const res = await this._sessionService.getParticipants(
                this.organizaerQuery
            );
            if (res && this._utils.isData<string[]>(res)) {
                this.participantSuggess = res;
                this.setupParticipantFiltering();
            } else {
                this.participantSuggess = [];
            }
        } catch (error) {
            console.error(error);
            this.participantSuggess = [];
        }
    }

    setupParticipantFiltering(): void {
        this.filterdParticipant = this.participants.controls.map(
            (control, index) =>
                control.get('email')!.valueChanges.pipe(
                    startWith(''),
                    map((value) => this._filterParticipant(value))
                )
        );
    }

    _filterParticipant(value: string): string[] {
        const filterValue = value?.toLowerCase();
        return this.participantSuggess.filter((option) =>
            option?.toLowerCase().includes(filterValue)
        );
    }

    addParticipant(): void {
        if (this.areAllEmailsValidAndNonEmpty()) {
            this.participants.insert(0, this.createParticipant());
            this.setupParticipantFiltering();
        }
    }

    createParticipant(): FormGroup {
        return new FormGroup({
            email: new FormControl('', [
                Validators.email,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
                this.duplicateEmailValidator.bind(this),
            ]),
        });
    }

    removeParticipant(i: number) {
        if (this.participants.length > 0) {
            this.participants.removeAt(i);
        }
    }

    get participants() {
        return this.form.get('participants') as FormArray;
    }

    areAllEmailsValidAndNonEmpty(): boolean {
        return this.participants.controls.every((participant) => {
            const emailControl = participant.get('email');
            return emailControl?.valid && emailControl.value !== '';
        });
    }

    duplicateEmailValidator(control: AbstractControl) {
        if (!control.parent) {
            return null;
        }

        const emailValue = control.value?.toLowerCase();
        const emailList = this.participants.controls
            .map((participant) =>
                participant.get('email')?.value?.toLowerCase()
            )
            .filter((email) => email);

        const duplicates = emailList.filter((email) => email === emailValue);

        return duplicates.length > 1 ? { duplicateEmail: true } : null;
    }

    // file upload
    async handleFileChange(
        event: Event,
        fileList:
            | 'uploadedFiles'
            | 'uploadedReportFiles'
            | 'uploadedAgendaFiles',
        uploadingFlag:
            | 'uploadingFile'
            | 'uploadingReportFile'
            | 'uploadingAgendaFile'
    ) {
        try {
            const files = (event.target as HTMLInputElement).files;
            if (files.length > 0) {
                this[uploadingFlag] = true;
                for (let index = 0; index < files.length; index++) {
                    const file: File = files[index];
                    const orgId = this.routes[1];
                    const res = await this._fileService.uploadFileWithOrg(
                        file,
                        orgId
                    );
                    if (res && this._utils.isData<AppFile>(res)) {
                        const fileItem: AppFile = res;
                        fileItem.isNew = true;
                        this[fileList].push(fileItem);
                    } else {
                        this[uploadingFlag] = false;
                        console.error(res);
                        break;
                    }
                }
                this.fileInput.nativeElement.value = '';
                this[uploadingFlag] = false;
            }
        } catch (error) {
            this.fileInput.nativeElement.value = '';
            console.error(error);
        }
    }

    async handleFileDelete(
        fileId: string,
        fileList:
            | 'uploadedFiles'
            | 'uploadedReportFiles'
            | 'uploadedAgendaFiles'
    ) {
        try {
            const res = await this._fileService.deleteFile(fileId);
            if (res) {
                this[fileList] = this[fileList].filter(
                    (file) => file._id !== fileId
                );
            }
        } catch (error) {
            console.error(error);
        }
    }

    async onSubmitHandler(form: FormGroup) {
        if (form.valid && this.organizerControl.valid) {
            const data = {
                startDateTime: this.form.get('startDateTime').value,
                endDateTime: this.form.get('endDateTime').value,
                subject: form.get('subject').value,
                organizer: this.organizerControl?.value,
                organization: this.routes[1],
                room: this.getDeviceName(this.routes[2]),
                participants: [],
                creator: this.getUserId(),
                attachments: this.uploadedFiles.map((file) => file._id),
                agendaFiles: this.uploadedAgendaFiles.map((file) => file._id),
                reportFiles: this.uploadedReportFiles.map((file) => file._id),
            };

            this.participants.controls.forEach((control) => {
                const email = control.get('email')?.value;
                if (email) {
                    data.participants.push(email);
                }
            });

            try {
                if (this.isForUpdate) {
                    const res = await this._sessionService.updateSession(
                        this.sessionId,
                        data
                    );

                    if (res) {
                        this.handleUpdateSuccuss(res);
                    }
                } else {
                    const res = await this._sessionService.createPublicSession(
                        data
                    );

                    if (res) {
                        this.handleSubmitSuccuss(res);
                    }
                }
            } catch (error) {
                console.error(error);
                this.handleSubmitError(error);
            } finally {
                this.isForUpdate = false;
            }
        } else {
            form.markAllAsTouched();
        }
    }

    async handleSubmitSuccuss(session: any) {
        const dialogData: BaseDialogData = {
            isForUpdate: false,
            data: {
                item: session,
            },
        };

        const dialogRef = this._dialog.open(BookMeetingDialogComponent, {
            data: dialogData,
            panelClass: 'custom-book-meeting-dialog',
            maxWidth: '90vw',
        });
        const result = await dialogRef.afterClosed().toPromise();

        if (result?.confirmed) {
            await this.getSessions(this.sessionQuery);
            this.onClearForm();
        }
    }

    async handleUpdateSuccuss(session: any) {
        const dialogData: BaseDialogData = {
            isForUpdate: true,
            data: {
                item: session,
            },
        };

        const dialogRef = this._dialog.open(BookMeetingDialogComponent, {
            data: dialogData,

            panelClass: 'custom-book-meeting-dialog',
            maxWidth: '90vw',
        });
        const result = await dialogRef.afterClosed().toPromise();

        if (result?.confirmed) {
            await this.getSessions(this.sessionQuery);
            this.onClearForm();
        }
    }

    onClearForm() {
        this.getCurrentTime();
        this.organizerControl.reset();
        this.sessionQuery['date'] = new Date();
        this.participants.clear();
        this.setupOrganizerFiltering();
        this.uploadedFiles = [];
        this.uploadedAgendaFiles = [];
        this.uploadedReportFiles = [];
        this.formGroupDirective.resetForm();

        setTimeout(() => {
            this.form.patchValue({
                startTime: this.currentTime,
                endTime: this.addOneHour(this.currentTime),
                startDateTime: this.currentDateTime,
                endDateTime: this.dateTimeAddOneHour(this.currentDateTime),
                subject: '',
                participants: [],
                attachments: [],
                agendaFiles: [],
                reportFiles: [],
            });
        });

        setTimeout(() => {
            this.scrollToTopWhenClickUpdate();
        }, 0);

        this.isForUpdate = false;
    }

    handleSubmitError(error: any) {
        if (error.message === 'ConflictSession') {
            this.form.get('endTime')?.setErrors({ conflict: true });
        } else {
            this._snackBar.openSnackBar(
                this._translateService.instant(error),
                'OK',
                2000,
                'center',
                'top'
            );
        }
    }

    // utils func
    getTimeFn(date: any) {
        const currentValue = new Date(date).toLocaleTimeString('en', {
            timeStyle: 'short',
            hour12: true,
        });

        const time = currentValue.replace(/\s/g, '');

        return time;
    }

    getDeviceName(urlText: string) {
        return decodeURIComponent(urlText);
    }

    addOneHour(time) {
        let [timePart, period] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        hours += 1;
        if (hours === 24) {
            hours = 0;
        }
        period = hours < 12 ? 'AM' : 'PM';

        if (hours > 12) {
            hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    dateTimeAddOneHour(dateTime) {
        const date = new Date(dateTime);
        date.setHours(date.getHours() + 1);

        return date;
    }
}
