<div class="meeting">
    <div class="navbar">
        <img src="../../../assets/images/polyview.png" alt="" />
    </div>
    <div class="meeting-container">
        <div class="meeting-form-container">
            <div class="meeting-form">
                <div class="title">
                    {{ "កក់បន្ទប់" | translate }} {{ getDeviceName(routes[2]) }}
                </div>
                <form
                    [formGroup]="form"
                    (submit)="onSubmitHandler(form)"
                    id="meeting"
                >
                    <div class="form-container">
                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-form-field
                                    class="custom-meeting-input full-width"
                                    appearance="outline"
                                >
                                    <mat-label>{{
                                        "កាលបរិច្ឆេទ" | translate
                                    }}</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="startPicker"
                                        formControlName="startDateTime"
                                    />
                                    <mat-datepicker-toggle
                                        matIconSuffix
                                        [for]="startPicker"
                                    ></mat-datepicker-toggle>

                                    <mat-datepicker
                                        #startPicker
                                    ></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-sm-6 mb-2">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width time-field"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "ម៉ោងចាប់ផ្ដើម" | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            [ngxMatTimepicker]="startTimePicker"
                                            formControlName="startTime"
                                        />
                                        <mat-icon
                                            matSuffix
                                            (click)="startTimePicker.open()"
                                            >keyboard_arrow_down</mat-icon
                                        >
                                        <ngx-mat-timepicker
                                            #startTimePicker
                                        ></ngx-mat-timepicker>
                                        @if (form.get('startTime').touched &&
                                        form .get('startTime')
                                        .hasError('required')) {
                                        <mat-error>
                                            {{
                                                "ទាមទារម៉ោងចាប់ផ្ដើម"
                                                    | translate
                                            }}
                                        </mat-error>
                                        } @if ( form.get('startTime')
                                        .hasError('conflict')) {
                                        <mat-error>
                                            {{ "ជាន់ការប្រជុំ" | translate }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>

                            <div class="col-12 col-sm-6">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width time-field"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "ម៉ោងបញ្ចប់" | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            [ngxMatTimepicker]="endTimePicker"
                                            formControlName="endTime"
                                        />

                                        <mat-icon
                                            matSuffix
                                            (click)="endTimePicker.open()"
                                            >keyboard_arrow_down</mat-icon
                                        >
                                        <ngx-mat-timepicker
                                            #endTimePicker
                                        ></ngx-mat-timepicker>
                                        @if ( form.get('endTime')
                                        .hasError('required')) {
                                        <mat-error>
                                            {{ "ទាមទារការប្រជុំ" | translate }}
                                        </mat-error>
                                        } @if (
                                        form.get('endTime').hasError('conflict'))
                                        {
                                        <mat-error>
                                            {{ "ជាន់ការប្រជុំ" | translate }}
                                        </mat-error>
                                        } @if (
                                        form.get('endTime').hasError('endTimeBeforeStartTime'))
                                        {
                                        <mat-error>
                                            {{
                                                "ម៉ោងបញ្ចប់ត្រូវតែក្រោយម៉ោងចាប់ផ្ដើម"
                                                    | translate
                                            }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "user_role_page.form.subject"
                                                | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            type="text"
                                            formControlName="subject"
                                        />
                                        @if (form.get('subject').touched && form
                                        .get('subject') .hasError('required')) {
                                        <mat-error>
                                            {{
                                                "user_role_page.form.errors.subject_required"
                                                    | translate
                                            }}
                                        </mat-error>
                                        } @if (form.get('subject').touched &&
                                        form .get('subject')
                                        .hasError('minlength')) {
                                        <mat-error>
                                            {{
                                                "ទាមទារយ៉ាងតិចបីតួរអក្សរ"
                                                    | translate
                                            }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "user_role_page.form.organizer"
                                                | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            [formControl]="organizerControl"
                                            [matAutocomplete]="organizer"
                                            (blur)="onOrganizerTouched()"
                                        />
                                        <mat-autocomplete
                                            #organizer="matAutocomplete"
                                        >
                                            @for (item of filterdOrganizer |
                                            async; track $index) {
                                            <mat-option [value]="item">
                                                {{ item }}
                                            </mat-option>
                                            }
                                        </mat-autocomplete>
                                        @if
                                        (organizerControl.hasError('required'))
                                        {
                                        <mat-error>
                                            {{
                                                "user_role_page.form.errors.organizer_required"
                                                    | translate
                                            }}
                                        </mat-error>
                                        } @if
                                        (organizerControl.hasError('minlength'))
                                        {
                                        <mat-error>
                                            {{
                                                "ទាមទារយ៉ាងតិចបីតួរអក្សរ"
                                                    | translate
                                            }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row participant-container">
                            <div class="col-12">
                                <div class="header">
                                    <div class="part-list">
                                        អ្នកចូលរួម ({{
                                            participants.controls.length
                                        }})
                                    </div>
                                    <button
                                        type="button"
                                        mat-flat-button
                                        color="primary"
                                        class="default-btn rounded"
                                        (click)="addParticipant()"
                                    >
                                        <mat-icon>mail</mat-icon>
                                        <span>{{
                                            participants.controls.length > 0
                                                ? ("បន្ថែម" | translate)
                                                : ("បញ្ចូល" | translate)
                                        }}</span>
                                    </button>
                                </div>
                            </div>

                            <div formArrayName="participants" class="content">
                                <div
                                    *ngFor="
                                        let participant of participants.controls;
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                    class="participant-list"
                                >
                                    <span>{{ i + 1 }}.</span>
                                    <div class="participant-form">
                                        <app-input-field>
                                            <mat-form-field
                                                class="full-width"
                                                appearance="outline"
                                            >
                                                <mat-label>{{
                                                    "អ៊ីម៉ែល" | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    formControlName="email"
                                                    [matAutocomplete]="
                                                        participantList
                                                    "
                                                />
                                                <mat-autocomplete
                                                    #participantList="matAutocomplete"
                                                >
                                                    <mat-option
                                                        *ngFor="
                                                            let item of filterdParticipant[
                                                                i
                                                            ] | async
                                                        "
                                                        [value]="item"
                                                    >
                                                        {{ item }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                @if
                                                (participant.get('email').hasError('email')
                                                ||
                                                participant.get('email').hasError('pattern')
                                                ) {
                                                <mat-error
                                                    >អ៊ីម៉ែលមិនត្រឹមត្រូវ</mat-error
                                                >
                                                } @if
                                                (participant.get('email').hasError('duplicateEmail')
                                                ) {
                                                <mat-error
                                                    >អ៊ីម៉ែលនេះបានបញ្ចូលរួចហើយ</mat-error
                                                >
                                                }
                                            </mat-form-field>
                                        </app-input-field>
                                    </div>
                                    <mat-icon
                                        svgIcon="delete"
                                        (click)="removeParticipant(i)"
                                        >delete</mat-icon
                                    >
                                </div>
                            </div>
                        </div>

                        <!-- normal file -->
                        <div class="row mt-1">
                            <div class="col-12 file-section">
                                <div
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <div class="docs">
                                        ឯកសារ ({{ uploadedFiles.length }})
                                    </div>
                                    <button
                                        type="button"
                                        top-action
                                        mat-stroked-button
                                        class="default-btn rounded upload"
                                        (click)="fileInput.click()"
                                    >
                                        <mat-icon>post_add</mat-icon>
                                        <span>{{
                                            uploadedFiles.length
                                                ? ("បន្ថែម" | translate)
                                                : ("បញ្ចូល" | translate)
                                        }}</span>
                                    </button>
                                </div>

                                <div class="file-container">
                                    <div class="file">
                                        @for (file of uploadedFiles; track file)
                                        {
                                        <div class="file-item">
                                            <app-file-item-for-phone
                                                [file]="file"
                                                [allowDelete]="true"
                                                [allowView]="true"
                                                (onDeleteClick)="
                                                    handleFileDelete(
                                                        file._id,
                                                        'uploadedFiles'
                                                    )
                                                "
                                            >
                                            </app-file-item-for-phone>
                                        </div>
                                        }
                                    </div>

                                    <input
                                        type="file"
                                        style="display: none"
                                        #fileInput
                                        (change)="
                                            handleFileChange(
                                                $event,
                                                'uploadedFiles',
                                                'uploadingFile'
                                            )
                                        "
                                        multiple
                                        [disabled]="uploadingFile"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- agenda file -->
                        <div class="row mt-3">
                            <div class="col-12 file-section">
                                <div
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <div class="docs">
                                        ឯកសាររបៀបវារៈ ({{
                                            uploadedAgendaFiles.length
                                        }})
                                    </div>
                                    <button
                                        type="button"
                                        top-action
                                        mat-stroked-button
                                        class="default-btn rounded upload"
                                        (click)="agendaFileInput.click()"
                                    >
                                        <mat-icon>post_add</mat-icon>
                                        <span>{{
                                            uploadedAgendaFiles.length > 0
                                                ? ("បន្ថែម" | translate)
                                                : ("បញ្ចូល" | translate)
                                        }}</span>
                                    </button>
                                </div>

                                <div class="file-container">
                                    <div class="file">
                                        @for (file of uploadedAgendaFiles; track
                                        file) {
                                        <div class="file-item">
                                            <app-file-item-for-phone
                                                [file]="file"
                                                [allowDelete]="true"
                                                [allowView]="true"
                                                (onDeleteClick)="
                                                    handleFileDelete(
                                                        file._id,
                                                        'uploadedAgendaFiles'
                                                    )
                                                "
                                            >
                                            </app-file-item-for-phone>
                                        </div>
                                        }
                                    </div>

                                    <input
                                        type="file"
                                        style="display: none"
                                        #agendaFileInput
                                        (change)="
                                            handleFileChange(
                                                $event,
                                                'uploadedAgendaFiles',
                                                'uploadingAgendaFile'
                                            )
                                        "
                                        multiple
                                        [disabled]="uploadingAgendaFile"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- report file -->
                        <div class="row mt-3">
                            <div class="col-12 file-section">
                                <div
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <div class="docs">
                                        ឯកសាររបាយការណ៏ ({{
                                            uploadedReportFiles.length
                                        }})
                                    </div>
                                    <button
                                        type="button"
                                        top-action
                                        mat-stroked-button
                                        class="default-btn rounded upload"
                                        (click)="reportFileInput.click()"
                                    >
                                        <mat-icon>post_add</mat-icon>
                                        <span>{{
                                            uploadedReportFiles.length > 0
                                                ? ("បន្ថែម" | translate)
                                                : ("បញ្ចូល" | translate)
                                        }}</span>
                                    </button>
                                </div>

                                <div class="file-container">
                                    <div class="file">
                                        @for (file of uploadedReportFiles; track
                                        file) {
                                        <div class="file-item">
                                            <app-file-item-for-phone
                                                [file]="file"
                                                [allowDelete]="true"
                                                [allowView]="true"
                                                (onDeleteClick)="
                                                    handleFileDelete(
                                                        file._id,
                                                        'uploadedReportFiles'
                                                    )
                                                "
                                            >
                                            </app-file-item-for-phone>
                                        </div>
                                        }
                                    </div>

                                    <input
                                        type="file"
                                        style="display: none"
                                        #reportFileInput
                                        (change)="
                                            handleFileChange(
                                                $event,
                                                'uploadedReportFiles',
                                                'uploadingReportFile'
                                            )
                                        "
                                        multiple
                                        [disabled]="uploadingReportFile"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="button-container">
                            @if (!isForUpdate) {
                            <button
                                type="submit"
                                form="meeting"
                                mat-flat-button
                                color="primary"
                                class="default-btn rounded"
                            >
                                <mat-icon>save</mat-icon>
                                <span>{{ "បង្កើតការប្រជុំ" | translate }}</span>
                            </button>
                            }@else {
                            <button
                                type="button"
                                mat-flat-button
                                color="primary"
                                class="default-btn rounded cancel-btn"
                                (click)="onClearForm()"
                            >
                                <mat-icon>close</mat-icon>
                                <span>{{ "បោះបង់" | translate }}</span>
                            </button>
                            <button
                                type="submit"
                                form="meeting"
                                mat-flat-button
                                color="primary"
                                class="default-btn rounded update-btn"
                            >
                                <mat-icon color="primary">edit</mat-icon>
                                <span>{{ "កែប្រែ" | translate }}</span>
                            </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="existing-meeting-container">
            <div class="header">
                <div class="title">
                    {{ "user_role_page.existingMeeting" | translate }}
                </div>
                <div class="date">
                    {{ form.get("startDateTime").value | date }}
                </div>
            </div>

            @if (isLoading && !hasFetched) {
            <div></div>
            } @if (!isLoading && hasFetched) { @if (sessions.length > 0) {
            <div class="content">
                @for (meeting of sessions; track $index) {
                <div class="card">
                    <div class="header">
                        <div>
                            <p class="time">
                                {{ getTimeFn(meeting.startDateTime) }} ~
                                {{ getTimeFn(meeting.endDateTime) }}
                            </p>
                            <p class="subject">{{ meeting.subject }}</p>
                        </div>

                        @if (meeting?.creator === currentUserId) {
                        <div class="custom-menu-btn">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                            >
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    mat-menu-item
                                    class="update-btn"
                                    (click)="onUpdateSession(meeting._id)"
                                >
                                    <mat-icon>edit</mat-icon>
                                    <span>{{
                                        "organization_page.update" | translate
                                    }}</span>
                                </button>

                                <button
                                    mat-menu-item
                                    class="delete-btn"
                                    (click)="onDeleteSession(meeting._id)"
                                >
                                    <mat-icon>delete</mat-icon>
                                    <span>{{
                                        "organization_page.delete" | translate
                                    }}</span>
                                </button>
                            </mat-menu>
                        </div>
                        }
                    </div>
                    <div class="footer-container">
                        <div class="room">
                            <mat-icon>meeting_room</mat-icon>
                            <p>{{ getDeviceName(meeting.room) }}</p>
                        </div>
                        <div class="organizer">
                            <mat-icon>person</mat-icon>
                            <p>{{ meeting.organizer }}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
            }@if(sessions.length === 0) {
            <div class="content">
                <div class="empty">
                    <img src="../../../assets/images/no_data.png" alt="" />
                    <p>{{ "user_role_page.noExistingMeeting" | translate }}</p>
                </div>
            </div>
            } }
        </div>
    </div>
</div>
