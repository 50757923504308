import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppFile } from '../../../models/bases/app-file';
import { FileService } from '../../../services/file.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-file-item-for-phone',
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
    templateUrl: './file-item-for-phone.component.html',
    styleUrl: './file-item-for-phone.component.scss',
})
export class FileItemForPhoneComponent {
    @Input() file: AppFile;
    @Input() allowView?: boolean;
    @Input() allowDownload?: boolean;
    @Input() allowDelete?: boolean;

    @Output() onDeleteClick: EventEmitter<void> = new EventEmitter();

    constructor(readonly _fileService: FileService) {}

    view = async () => {
        try {
            const file = await this._fileService.getFile(this.file._id);
            if (file) {
                const url = window.URL.createObjectURL(file);
                window.open(url, '_blank');
            }
        } catch (error) {
            console.error(error);
        }
    };

    download = async () => {
        try {
            const blob = await this._fileService.getFile(this.file._id);
            saveAs(blob, this.file.filename);
        } catch (error) {
            console.error(error);
        }
    };

    delete() {
        this.onDeleteClick.emit();
    }

    getFileSize(sizeInBytes: number) {
        return (sizeInBytes * Math.pow(10, -6)).toFixed(2);
    }
}
